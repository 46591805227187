<template>
  <b-card>
    <basetable
      v-if="tampilkan"
      ref="basetable"
      title="Kelengkapan Kendaraan"
      :dataurl="dataurl"
      :fields="fields"
      :isFilter="false"
      :is-edit="false"
      :is-delete="false"
      :is-add="false"
      :is-view="false"
      :isPagination="false"
      @showData="showData"
    >
      <template #cell(is_exist)="data">
        <b-form-checkbox 
          v-model="data.item.is_exist"
          class="custom-control-primary"
          name="check-button"
          switch
        />
      </template>
      <template #cell(is_work)="data">
        <b-form-checkbox 
          v-model="data.item.is_work"
          class="custom-control-primary"
          name="check-button"
          switch
        />
      </template>
      <template #cell(is_good)="data">
        <b-form-checkbox 
          v-model="data.item.is_good"
          class="custom-control-primary"
          name="check-button"
          switch
        />
      </template>
    </basetable>
    <basetable
      v-if="tampilkan"
      ref="basetableBodies"
      title="Body Kendaraan"
      :dataurl="dataurlBody"
      :fields="fields"
      :isFilter="false"
      :is-edit="false"
      :is-delete="false"
      :is-add="false"
      :is-view="false"
      :isPagination="false"
      @showData="showDataBody"
    >
      <template #cell(is_exist)="data">
        <b-form-checkbox 
          v-model="data.item.is_exist"
          class="custom-control-primary"
          name="check-button"
          switch
        />
      </template>
      <template #cell(is_work)="data">
        <b-form-checkbox 
          v-model="data.item.is_work"
          class="custom-control-primary"
          name="check-button"
          switch
        />
      </template>
      <template #cell(is_good)="data">
        <b-form-checkbox 
          v-model="data.item.is_good"
          class="custom-control-primary"
          name="check-button"
          switch
        />
      </template>
    </basetable>
    <b-form-group
      label-cols-md="0"
      label=""
    >
      <b-button
        class="mr-1"
        variant="primary"
        type="button"
        @click.prevent="validationForm"
      >
        <span v-if="loading">
          <b-spinner small />
          Loading...
        </span>
        <span v-else>Simpan</span>
      </b-button>
    </b-form-group>
  </b-card>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BRow, BFormCheckbox, BFormGroup, BButton, BSpinner } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    basetable,
    BCard,
    BRow,
    BFormCheckbox,
    BFormGroup,
    BButton,
    BSpinner
  },
  data() {
    return {
      // dataurl:"/vehicle/"+this.$route.params.vh_id+"/properties",
      dataurl:null,
      dataurlBody:null,
      tampilkan:false,
      fields: [
        { key: 'name', label: 'Nama', sortable: true },
        { key: 'is_exist', label: 'Ada' },
        { key: 'is_good', label: 'Baik' },
        { key: 'is_work', label: 'Berfungsi' },
      ],
      loading:false,
      dataProperties:[],
      dataBodies:[],
      vehicle_id:null
    }
  },
  mounted(){
    // this.getPhotos()
    this.showDataVehicle()
  },
  methods:{
    show(){
      this.$refs.basetable.fetchData()
      this.$refs.basetableBodies.fetchData()
    },
    showDataVehicle(){
      this.$http.get('/spb/'+this.$route.params.id+'/vehicle/'+this.$route.params.id_vehicle).then(res=>{
          this.modelVehicle = res.data
          this.modelVariant = res.data.variant
          if(res.data.vehicle_id != null){
            // this.getPhotos(res.data.vehicle_id)
            this.vehicle_id = res.data.vehicle_id
            this.dataurl = "/vehicle/"+res.data.vehicle_id+"/properties"
            this.dataurlBody = "/vehicle/"+res.data.vehicle_id+"/bodies"
            this.$refs.basetable
            this.$refs.basetableBodies
            this.tampilkan = true
          }else{
            this.tampilkan = false
          }
      })
    },
    showData(data){
      this.dataProperties = data
    },
    showDataBody(data){
      this.dataBodies = data
    },
    validationForm() {
      this.dataProperties = this.dataProperties.map(value => {
        return {
          ...value,
          is_exist: value.is_exist?true:false,
          is_good: value.is_good?true:false,
          is_work: value.is_work?true:false,
        }
      })
      this.dataBodies = this.dataBodies.map(value => {
        return {
          ...value,
          is_exist: value.is_exist?true:false,
          is_good: value.is_good?true:false,
          is_work: value.is_work?true:false,
        }
      })
      var params = {
        vehicle_id: this.vehicle_id,
        start_at: moment().format('Y-M-D HH:mm:ss'),
        is_worthy: true,
        properties: this.dataProperties,
        bodies:this.dataBodies
      }
      this.loading = true
      this.$http.post('/check', params)
        .then(()  => {
          this.show()
          this.successSubmit()
        })
        .catch(e=>{
          this.errorSubmit(e)
        })
        .finally(() => this.loading = false)
    },
    successSubmit(){
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  }
}
</script>
<style lang="">

</style>
