<template>
  <b-card>
    <b-row>
      <b-col
        v-if="!is_form"
        cols="12"
      >
        <b-row>
          <b-col cols="6">
            <b-button
              class="mr-1"
              size="sm"
              variant="success"
              type="button"
              @click.prevent="addForm"
            >
              <span>Tambah Perawatan Berkala</span>
            </b-button>
          </b-col>
          <b-col cols="6">
            <div class="float-right">
              <span
                class="badge rounded-pill bg-info mr-1"
              >P : Pemeriksaan</span>
              <span
                class="badge rounded-pill bg-info mr-1"
              >G : Penggantian</span>
              <span
                class="badge rounded-pill bg-info"
              >T : Penambahan</span>
            </div>
          </b-col>
        </b-row>
        <hr>
      </b-col>
      <b-col
        v-if="!is_form"
        cols="12"
      >
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >
          <b-table
            striped
            responsive
            :items="items"
            :fields="fieldsTable"
          >

            <template #cell(name)="data">
              <span class="badge bg-primary">
                {{ data.item.name }}
              </span>
            </template>
            <template #cell(_)="data">
              <div>
                <button
                  class="btn btn-primary btn-icon mr-1 btn-sm"
                  @click="editForm(data)"
                >
                  <feather-icon icon="EditIcon" />
                </button>
                <button
                  class="btn btn-danger btn-icon btn-sm"
                  @click="confirmDelete(data)"
                >
                  <feather-icon icon="TrashIcon" />
                </button>
              </div>
            </template>
            <template #cell(is_check)="data">
              <b-form-checkbox
                v-model="data.item.is_check" 
                class="custom-control-primary"
                name="check-button"
                switch
                @input="actionCheck(data.item)"
              />
            </template>
            <template #cell(cost)="data">
              {{ data.item.cost|numFormat }}
            </template>
            <template #cell(interval.distance)="data">
              {{ data.item.interval_type == 'km_based'?data.item.interval.distance:data.item.interval_type == 'time_based'?data.item.interval_time:'-' }}
            </template>
            <template #cell(satuan)="data">
              {{ data.item.interval_type == 'km_based'?'Km':data.item.interval_type == 'time_based'?data.item.interval_time_unit:'-' }}
            </template>
            <template #cell(cost_item)="data">
              {{ getCostItem(data.item)|numFormat }}
            </template>
            <template #cell(author.name)="data">
              {{ data.item.author?data.item.author.contact_type.split('\\').pop().toLowerCase() == 'staff'?'Internal':data.item.author.contact_id == user.id?user.name:'Vendor Lain':'' }}
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col
        v-if="is_form"
        cols="12"
      >
        <strong v-if="!is_edit"><h3>Tambah jenis Perawatan Berkala</h3></strong>
        <strong v-if="is_edit"><h3>Edit jenis Perawatan Berkala</h3></strong>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col cols="6">
                <br>
                <form-input
                  v-model="description"
                  rules="required"
                  label="Deskripsi"
                  placeholder="Deskripsi"
                />
                <form-select
                  v-model="interval_type"
                  rules="required"
                  label="Tipe Interval"
                  placeholder="Tipe Interval"
                  :field="{val:'slug', contain:'name'}"
                  :options="optionIntervalType"
                  @input="changeSelectInterval"
                />
                <b-form-group
                  label-cols-md="4"
                  label="Interval"
                  label-for="interval"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Interval"
                    rules="required"
                  >
                    <b-row class="form-group col-md-12">
                      <cleave
                        v-if="interval_type != 'km_based'"
                        v-model="interval"
                        class="form-control col-md-4 mr-1"
                        :raw="true"
                        :options="optionscleave.number"
                        placeholder="Interval"
                      />
                      <b-form-select
                        v-if="interval_type == 'time_based'"
                        v-model="interval_time_unit"
                        class="col-md-6"
                        :options="[{slug:'hari', name:'Hari'}, {slug:'bulan', name:'Bulan'}]"
                        disabled-field="disabled"
                        value-field="slug"
                        text-field="name"
                      />
                      <b-form-select
                        v-if="interval_type == 'km_based'"
                        v-model="interval_km_based"
                        class="col-md-10"
                        :options="optionInterval"
                        disabled-field="disabled"
                        value-field="id"
                        text-field="distance"
                      />
                    </b-row>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="4"
                  label="Lama (jam) & Biaya Perawatan"
                  label-for="time"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lama (jam) & Biaya Perawatan"
                    rules="required"
                  >
                    <b-row class="form-group col-md-12">
                      <cleave
                        v-model="time"
                        class="form-control col-md-4 mr-1"
                        :raw="true"
                        :options="optionscleave.number"
                        placeholder="Waktu"
                      />
                      <cleave
                        v-model="cost"
                        class="form-control col-md-6 mr-1"
                        :raw="true"
                        :options="optionscleave.number"
                        placeholder="Biaya"
                      />
                    </b-row>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <form-input
                  v-model="note"
                  rules="required"
                  label="Keterangan"
                  placeholder="Keterangan"
                />
              </b-col>
              <b-col cols="6">
                <br>
                <form-v-select
                  ref="maintenance_item"
                  v-model="maintenance_item"
                  label="Jenis Perawatan"
                  placeholder="Jenis Perawatan"
                  :options="optionItemMaintenace"
                />
                <form-v-select
                  v-model="maintenance_type"
                  label="Tipe Kegiatan"
                  placeholder="Tipe Kegiatan"
                  :options="optionTypeMaintenace"
                />
                <b-form-group
                  label-cols-md="4"
                  label="Jumlah / Qty"
                  label-for="qty"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah / Qty"
                    rules="required"
                  >
                    <cleave
                      v-model="qty"
                      class="form-control col-md-6 mr-1"
                      :raw="true"
                      :options="optionscleave.number"
                      placeholder="Jumlah / Qty"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="4"
                  label="Biaya Satuan"
                  label-for="cost_item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Biaya Satuan"
                    rules="required"
                  >
                    <cleave
                      v-model="cost_item"
                      class="form-control col-md-6 mr-1"
                      :raw="true"
                      :options="optionscleave.number"
                      placeholder="Biaya Satuan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button
                  style="float:right;"
                  class="mr-1"
                  size="sm"
                  variant="primary"
                  type="button"
                  @click.prevent="addItem"
                >
                  <span>Tambah Ke Table</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <hr>
        <b-col cols="12">
          <b-table
            striped
            responsive
            :items="itemDetails"
            :fields="fieldsTableItems"
          >

            <template #cell(_)="data">
              <b-button
                size="sm"
                variant="warning"
                class="mr-1 btn-icon"
                @click="removeItem(data)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
            <template #cell(cost_item)="data">
              {{ data.item.cost|numFormat }}
            </template>
            
          </b-table>
        </b-col>
        <hr>
        <b-button
          class="mr-1"
          size="sm"
          variant="primary"
          type="button"
          @click.prevent="validationForm"
        >
          <span>Simpan</span>
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="batal"
        >
          <span>Batal</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BCard, BCol, BRow, BTable, BOverlay, BButton, BLink, BBadge, BForm, BFormGroup, BFormSelect, BFormCheckbox } from 'bootstrap-vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormSelect from '@/views/base/form/FormSelect.vue'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BButton,
    BLink,
    BBadge,
    FormInput,
    FormSelect,
    FormVSelect,
    BFormCheckbox,
    Cleave,
    ToastificationContent
  },
  data() {
    return {
      user:null,
      dataurlvarian:"/vehicle-variant/"+this.$route.params.variant_id+"/schedule",
      fieldsTable: [
        { key: 'is_check', label: 'Gunakan Perawatan'},
        { key: 'author.name', label: 'Dibuat Oleh'},
        { key: 'description', label: 'Deskripsi'},
        { key: 'name', label: 'Nama Perawatan'},
        { key: 'interval_type', label: 'Tipe Interval'},
        { key: 'interval.distance', label: 'Interval'},
        { key: 'satuan', label: 'Satuan'},
        { key: 'rate_hour', label: 'Lama Jam Perawatan'},
        { key: 'cost', label: 'Biaya Perawatan'},
        { key: 'cost_item', label: 'Biaya Item'}
      ],
      optionIntervalType: [
        {slug:'sekali', name:'Sekali'},
        {slug:'time_based', name:'Time Based'},
        {slug:'km_based', name:'KM Based'},
      ],
      optionInterval:[],
      optionItemMaintenace: [],
      optionTypeMaintenace: [
        {slug: 'periksa', name: 'Pemeriksaan'},
        {slug: 'ganti', name: 'Penggantian'},
        {slug: 'tambah', name: 'Penambahan'}
      ],
      items:[],
      datatable:[],
      loading:false,
      is_form:false,
      is_edit:false,
      edit_id:"",
      optionscleave: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      description:"",
      interval_type:"",
      interval:1,
      interval_time_unit:"hari",
      time:1,
      cost:0,
      note:"",
      maintenance_item:"",
      maintenance_type:"",
      qty:1,
      cost_item:0,
      interval_km_based:"",
      fieldsTableItems: [
        { key: 'item_name', label: 'Jenis Perawatan'},
        { key: 'activity_type_name', label: 'Kegiatan'},
        { key: 'qty', label: 'Qty'},
        { key: 'cost', label: 'Biaya Satuan'},
        "_"
      ],
      itemDetails:[],
      vendor_id:null
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.user = this.$store.state.profile.info.contact
    }
  },
  mounted(){
    this.fetchData()
  },
  methods: {
    async fetchData(){
      let schedules = await this.$http.get('spb/'+this.$route.params.id);
      this.vendor_id = schedules.data.vendor_id
      schedules = schedules.data.schedules

      this.$http.get(this.dataurlvarian).then(res=>{
        this.datatable = res.data.data
        for(let i = 0; i<res.data.items.length; i++){
          if(res.data.items[i]['name'] != null)
            this.fieldsTable.push(
              {
                key: res.data.items[i]['name'].toLowerCase().replaceAll(" ", "_"), 
                label:res.data.items[i]['name']
              })
        }

        for(let i = 0; i<this.datatable.length; i++){
          for(let j = 0; j<this.datatable[i].details.length; j++){
            let activity_type = ""
            if(Object.keys(this.datatable[i]).find(x => x === this.datatable[i].details[j]['item']['name'].toLowerCase().replaceAll(" ", "_"))){
              activity_type = this.datatable[i][this.datatable[i].details[j]['item']['name'].toLowerCase().replaceAll(" ", "_")]+" | "+this.datatable[i].details[j]['activity_type'].charAt(0).toUpperCase()
            }else{
              activity_type = this.datatable[i].details[j]['activity_type'].charAt(0).toUpperCase()
            }
            this.datatable[i][this.datatable[i].details[j]['item']['name'].toLowerCase().replaceAll(" ", "_")] 
            = activity_type
          }
        }
        
        this.datatable = this.datatable.map(value => {
          return {
            ...value,
            is_check: schedules.find(x => x.schedule_id == value.id)?true:false,
          }
        })

        this.items = []
        this.items = this.datatable
        this.fieldsTable.push('_')
      })
      
    },
    fetchDataItemMaintenance(){
      this.$http.get('maintenance-item').then(res=>{
        this.optionItemMaintenace = res.data.data
        this.$refs.maintenance_item.refreshData(this.optionItemMaintenace)
      })
    },
    fetchDataIntervalMaintenance(){
      this.$http.get('maintenance-interval').then(res=>{
        this.optionInterval = res.data.data
      })
    },
    addForm(){
      this.is_form = true
      this.is_edit = false
      this.fetchDataItemMaintenance()
      this.fetchDataIntervalMaintenance()
      this.reset()
      // this.$refs.simpleRules.reset()
    },
    batal(){
      this.is_form = false
      // this.$refs.simpleRules.reset()
    },
    editForm(data){
      this.fetchDataItemMaintenance()
      this.fetchDataIntervalMaintenance()
      this.edit_id = data.item.id
      this.reset()
      this.$http.get('maintenance-schedule/'+this.edit_id).then(res=>{
        this.addForm()
        this.is_edit = true

        let data = res.data
        this.description = data.name
        this.note = data.description
        this.interval_type = data.interval_type
        this.interval = data.interval_time
        this.interval_time_unit = data.interval_time_unit??'hari'
        this.interval_km_based = data.interval_id
        this.time = data.rate_hour
        this.cost = data.cost
        for(let i =0; i<data.details.length; i++){
          let params = {
            activity_type: data.details[i].activity_type,
            activity_type_name: this.optionTypeMaintenace.find(x => x.slug == data.details[i].activity_type).name,
            cost: data.details[i].cost,
            qty: data.details[i].qty,
            item_id: data.details[i].item.id,
            item_name: data.details[i].item.name,
          }
          this.itemDetails.push(params)
        }
          
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then( res => {
        if(res){
          if(this.itemDetails.length == 0) 
            return this.$bvToast.toast('Detail item tidak boleh kosong', {
              title: 'Form Error',
              solid: true,
              variant:'danger'
            })

          let params = {
            name: this.description,
            description: this.note,
            cost: this.cost,
            service_cost: this.cost,
            rate_hour: this.time,
            interval_type: this.interval_type,
            interval_time: this.interval,
            interval_time_unit: this.interval_time_unit,
            interval_id: this.interval_km_based,
            variant_id: this.$route.params.variant_id,
            details: this.itemDetails
          }
          if(!this.is_edit)
            this.$http.post('maintenance-schedule', params).then(res=>{
              this.fetchData()
              this.batal()
              this.reset()
              // this.$refs.simpleRules.reset()
            })
          else
            this.$http.put('maintenance-schedule/'+this.edit_id, params).then(res=>{
              this.fetchData()
              this.batal()
              this.reset()
              // this.$refs.simpleRules.reset()
            })
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    changeSelectInterval(data){
      if(data != null){
        this.interval_type = data
      }else{
        this.interval_type = ""
      }
    },
    addItem(){
      if(!this.maintenance_type) 
        return this.$bvToast.toast('Kegiatan tidak boleh kosong', {
          title: 'Form Error',
          solid: true,
          variant:'danger'
        })

      if(!this.maintenance_item) 
        return this.$bvToast.toast('Jenis Perawatan tidak boleh kosong', {
          title: 'Form Error',
          solid: true,
          variant:'danger'
        })

      let params = {
        activity_type: this.maintenance_type.slug,
        activity_type_name: this.maintenance_type.name,
        cost: this.cost_item,
        qty: this.qty,
        item_id: this.maintenance_item.id,
        item_name: this.maintenance_item.name
      }
      this.itemDetails.push(params)
      this.cost_item = 0
      this.qty = 1
      this.maintenance_item = null
      this.maintenance_type = null
    },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm('Anda Ingin Menghapus Data ini?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete('maintenance-schedule/'+data.item.id)
              .then(() => {
                this.fetchData()
                this.batal()
              this.reset()
                this.$toast({
                component: ToastificationContent,
                  props: {
                    title: 'Delete Success',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
            })
          }
        })
    },
    removeItem(data){
      this.itemDetails.splice(data.index, 1)
    },
    reset(){
      this.description = ""
      this.note = ""
      this.cost = 0
      this.time = 1
      this.interval_type = ""
      this.interval = 1
      this.interval_time_unit = 'hari'
      this.interval_km_based = null
      this.itemDetails = []
    },
    getCostItem(data){
      var cost = 0
      if(data.details){
        var details = data.details
        details.forEach(detail => {
          cost += detail.cost
        })
      }
      return cost
    },
    actionCheck(data){
      this.loading = true
      this.items.find(x => x.id == data.id).is_check = data.is_check

      this.$http.post('maintenance-schedule-vendor', {schedule:this.items, vendor_id:this.vendor_id, variant_id:data.variant_id})
        .then(() => {
          this.fetchData()
          this.loading = false
          this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Insert Success',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
      })
    }
  }
}
</script>
