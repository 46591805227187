<template>
  <b-card>
    <div class="row">
      <div class="col-md-6">
        <form-label
          label="Model"
          :content="modelVariant?modelVariant.model:''"
        />
        <form-label
          label="Nopol"
          :content="modelVehicle?modelVehicle.number_plate:''"
        />
        <form-label
          label="Tahun Produksi"
          :content="String(modelVehicle?modelVehicle.year:'')"
        />
        <form-label
          label="Warna"
          :content="modelVehicle?modelVehicle.color:''"
        />
        <form-label
          label="NO STNK"
          :content="modelVehicle?modelVehicle.stnk_number:''"
        />
        <form-label
          label="Nama STNK"
          :content="modelVehicle?modelVehicle.stnk_name:''"
        />
        <form-label
          label="Tanggal STNK"
          :content="modelVehicle?modelVehicle.stnk_date:''"
          :type="'date'"
        />
        <form-label
          label="No BPKB"
          :content="modelVehicle?modelVehicle.bpkb_number:'-'"
        />
      </div>
      <div class="col-md-6">
        <form-label
          label="No Rangka"
          :content="modelVehicle?modelVehicle.frame_number:''"
        />
        <form-label
          label="No Mesin"
          :content="modelVehicle?modelVehicle.machine_number:''"
        />
        <form-label
          label="Jenis / Merk GPS"
          content="-"
        />
        <form-label
          label="No GSM / GPS"
          content="-"
        />
        <form-label
          label="No ID GPS"
          content="-"
        />
        <form-label
          label="Nama Ansuransi"
          :content="modelVehicle?modelVehicle.assurance?modelVehicle.assurance.name:'-':'-'"
        />
        <form-label
          label="Nama Bengkel"
          :content="modelVehicle?modelVehicle.workshop?modelVehicle.workshop.name:'-':'-'"
        />
        <form-label
          label="Tanggal Input"
          :content="modelVehicle?modelVehicle.delivered_date:'-'"
          :type="'date'"
        />
      </div>
    </div>
    <div class="form-group">
      <button
        class="btn btn-primary mr-1"
        @click.prevent="kembali"
      >
        Kembali
      </button>
      <button
        class="btn btn-info mr-1"
        @click.prevent="editVariant(modelVariant)"
      >
        Edit
      </button>
      <button
        class="btn btn-danger"
        @click="confirmDelete(modelVariant)"
      >
        Hapus
      </button>
    </div>
    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="6">
        <p>Sebaran Varian</p>
                
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            striped
            responsive
            :items="items"
            :fields="fieldsTable"
          />

        </b-overlay>
      </b-col>
      <b-col cols="6">
        <template>
          <vue-apex-charts 
            ref="realtimeChart" 
            type="pie" 
            height="350" 
            :options="chartOptions" 
            :series="dataseries"
          />
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {BCard, BCol, BRow, BTable, BOverlay} from 'bootstrap-vue'
import FormLabel from '@/views/base/form/FormLabel.vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
    FormLabel,
    BCol,
    BRow,
    BTable,
    BOverlay
  },

  data() {
    return {
      modelVehicle:null,
      modelVariant:null,
      model:null,
      loading:false,
      dataurlvarian:"/vehicle-variant/"+this.$route.params.variant_id+"/chart",
      datalabel:[],
      dataseries:[],
      items:[],
      fieldsTable: [
        { key: 'name', label: 'Region'},
        { key: 'count', label: 'Jumlah'},
      ],
      chartOptions: {
        // colors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  mounted(){
      this.showDataVehicle()
        // this.fetchData()
  },
  methods:{
      fetchData(){
      this.loading = true
      this.$http.get(this.dataurlvarian)
        .then(res  => {
          this.items = res.data.data
          for(let i = 0; i<res.data.data.length; i++){
            this.datalabel.push(res.data.data[i]['name'])
            this.dataseries.push(res.data.data[i]['count'])
            // this.dataseries.push(i+1)
          }
          this.$refs.realtimeChart.updateOptions({ labels: this.datalabel })
          // this.$refs.realtimeChart.updateOptions();
          
        })
        .catch(()=>{
          this.$bvToast.toast('Something wrong', {
            title: 'Fail',
            solid: true,
            variant:'warning'
          })
        })
        .finally(() => this.loading = false)
    },
    kembali(){
        this.$router.push({name:'persiapan-unit-vendor-show',params:{id:this.$route.params.id}})
    },
    showDataVehicle(){
      this.loading = true
        this.$http.get('/spb/'+this.$route.params.id+'/vehicle/'+this.$route.params.id_vehicle).then(res=>{
            this.modelVehicle = res.data
            this.modelVariant = res.data.variant
            this.fetchData()

        }).finally(() => this.loading = false)
    },
    editVariant(data){
        this.$router.push({name:'persiapan-unit-varian-edit',params:{id:data.id}})
    },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm(data.model != null ? 'Anda Ingin Menghapus Data '+data.model +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete('/vehicle-variant/'+this.$route.params.variant_id)
              .then(() => {
                this.getFile()
                this.$toast({
                component: ToastificationContent,
                  props: {
                    title: 'Delete Success',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
            })
          }
        })
    },
  }
}
</script>