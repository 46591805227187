<template>
  <b-card>
    <h3>{{ modelVehicle?modelVehicle.model:'' }}</h3>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon" />
          Detail Varian
        </template>
        <info />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" />
          Kelengkapan Kendaraan
        </template>
        <properties />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" />
          Perawatan Berkala
        </template>
        <maintenance />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" />
          Foto Kendaraan
        </template>
        <photos />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>

import {BCard,BTabs, BTab} from 'bootstrap-vue'
import Info from './InfoSPB.vue'
import Maintenance from './IndexMaintanance.vue'
import Properties from './IndexProperties.vue'
import Photos from './IndexPhotos.vue'
// import Dashboard from './Dashboard.vue'

export default {
  components: {
    BCard,
    BTabs, BTab,
    Info,
    Maintenance,
    Properties,
    Photos
  },
   data() {
    return {
      modelVehicle:null
    }
  },
  mounted(){
      this.showDataVehicle()
  },
  methods:{
    showDataVehicle(){
        this.$http.get('/spb/'+this.$route.params.id+'/vehicle/'+this.$route.params.id_vehicle).then(res=>{
            this.modelVehicle = res.data.variant
        })
    },
  }
}
</script>
