<template>
  <b-card>
    <div class="col-md-12 ">
      <div class="col-md-12 row">
        <div class="col-md-2 mr-2">
          <a
            v-if="data_profile && data_profile.length != 0"
            :href="data_profile[0].file_url"
            target="_blank"
          >
            <img
              :src="data_profile[0].file_url"
              class="rounded"
              width="100%"
              height="150"
            >
          </a>
        </div>
        <div class="col-md-6">
          <form-label
            label="Model"
            :content="modelVariant?modelVariant.model:''"
          />
          <form-label
            label="Nopol"
            :content="modelVehicle?modelVehicle.number_plate:''"
          />
          <form-label
            label="Tahun Produksi"
            :content="String(modelVehicle?modelVehicle.year:'')"
          />
          <form-label
            label="Warna"
            :content="modelVehicle?modelVehicle.color:''"
          />
        </div>
      </div>
      <hr>
      <div class="float-right">
        <button
          class="btn btn-success mb-3"
          @click="showUpload"
        >
          Upload Foto
        </button>
        <button
          v-if="setshow"
          class="btn btn-primary ml-1 mb-3"
          @click="batal_pilih"
        >
          Batalkan
        </button>
        <button
          v-else
          class="btn btn-primary ml-1 mb-3"
          @click="tampil_pilih"
        >
          Pilih
        </button>
      </div>
    </div>
    <div class="col-md-12 row mt-5">
      <div
        v-for="(records, index) in data_field"
        :key="index"
        class="col-md-2 mr-1"
      >
        <p>
          <a
            :href="records.file_url"
            target="_blank"
          >
            <img
              :src="records.file_url"
              class="rounded"
              width="100%"
              height="190"
            >
          </a>
        </p>
        <div
          v-if="bshow"
          class="float-right"
        >
          <button
            class="btn btn-sm btn-success"
            @click="confirmSetProfile(records)"
          >
            Set Profile
          </button>&nbsp;
          <button
            class="btn btn-sm btn-danger "
            @click="confirmDelete(records)"
          >
            Hapus
          </button>
        </div>
      </div>
            
            
    </div>
    <b-modal
      id="modal-center"
      ref="my-modal-form"
      :title="title_modal"
      centered
      hide-footer
    >
      <div class="form-group">
        <label>Pilih Foto</label>

        <b-form-file
          id="extension"
          v-model="datafile"
          accept=".jpg, .png"
          placeholder="Tidak ada file yang dipilih"
          @change="upload"
        />
      </div>

      <div class="form-group mt-3 float-right">
        <b-button
          class
          variant="outline-danger mr-1"
          @click="hideModal"
        >
          Batal
        </b-button>
        <b-button
          variant="primary"
          @click.prevent="submitForm"
        >
          Simpan
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BModal,BRow,BCol, BButton, BTable, BOverlay,BFormFile } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormLabel from '@/views/base/form/FormLabel.vue'

export default {
  components: {
    basetable,
    BFormFile,
    BModal,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    FormLabel,
    ToastificationContent
  },
  data() {
    return {
      // dataurl:'/vehicle/'+this.$route.params.vh_id+'/photos',
      dataurl:null,
      bshow:false,
      setshow:false,
      tombol_hide:true,
      title_modal : null,
      renderComp:true,
      dataVarian:null,
      data_doc:[],
      data_field:[],
      data_profile:null,
      datafile:null,
      modelVehicle:null,
      modelVariant:null,
      data_form:{
            file:null,
            data_file:null,
      },
    }
  },
  mounted(){
    // this.getPhotos()
    this.showDataVehicle()
  },
  methods:{
    showDataVehicle(){
        this.$http.get('/spb/'+this.$route.params.id+'/vehicle/'+this.$route.params.id_vehicle).then(response=>{
            this.modelVehicle = response.data
            this.modelVariant = response.data.variant
            if(response.data.vehicle_id != null){
              // this.getPhotos(res.data.vehicle_id)
              // this.$refs.BCard
              this.dataurl = '/vehicle/'+response.data.vehicle_id+'/photos';
              this.getPhotos()
            }
        })
    },
    getPhotos(){
      this.$http.get(this.dataurl).then(res=>{
                this.data_field = res.data.data
                let data_cari = res.data.data
                let cari_data = data_cari.filter(function(data_cari){
                    return data_cari.set == 1
                })
                // console.log(this.data_field)
                this.data_profile = cari_data
                // console.log(this.data_profile[0])

              })
    },
    show(){
      this.$refs.basetable.fetchData()
    },
    tampil_pilih(){
        this.setshow = true
        this.bshow = true
    },
    batal_pilih(){
        this.setshow = false
        this.bshow = false
    },
    showUpload(){
      this.title_modal = "Upload Foto"
      this.$refs['my-modal-form'].show()
    },
    hideModal(){
      this.$refs['my-modal-form'].hide()
    },
    upload(e){
       let files = e.target.files[0]
       this.data_form.data_file = files
    },
    submitForm(){
      let formData = new FormData()
      let filename = ""
      formData.append('file',this.data_form.data_file)
      this.$http.post('upload/image',formData).then(res=>{
        if(res.data){
              if(res.data.path.split("/")){
                filename = res.data.path.split("/").pop()
              }
              
              let params = {
                name: filename,
                file: res.data.path
              }
            this.data_form.file = res.data.path
            this.$http.post(this.dataurl,params).then(()=>{
                this.getPhotos()
                this.$refs['my-modal-form'].hide()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Submit Success',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
            })
        }
      })
      .catch(err=>{
        this.errorSubmit(err)
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    confirmSetProfile(data){
      this.$bvModal
        .msgBoxConfirm('Anda ingin menjadikan foto ini sebagai foto profile kendaraan?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/profile/'+data.id)
              .then(() => {
                this.getPhotos()
                this.setshow = false
                this.bshow = false
                this.$toast({
                component: ToastificationContent,
                  props: {
                    title: 'Change Set Profile Success',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
            })
          }
        })
    },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm('Anda Ingin Menghapus Data ini?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+data.id)
              .then(() => {
                this.getPhotos()
                this.setshow = false
                this.bshow = false
                this.$toast({
                component: ToastificationContent,
                  props: {
                    title: 'Delete Success',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
            })
          }
        })
    },
  }
}
</script>
<style lang="">

</style>
